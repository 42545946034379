<template>
  <div class="reservation">
    <d-search
      :searchData="searchData"
      @searchEvent="searchEvent"
      @resetEvent="resetEvent"
    />
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="small" type="primary" @click="addItem">
            添加
          </el-button>
        </div>
      </div>
      <d-table :columnData="columnData" :tableData="tableData">
        <template v-slot:handle="{ item }">
          <span class="mgr10 blue" @click="changeItem(item)">修改</span>
          <el-popconfirm
            title="您确定要删除该条数据吗?"
            @confirm="deleteItem(item)"
          >
            <span slot="reference" class="mgr10 colorRed">删除</span>
          </el-popconfirm>
        </template>
      </d-table>
    </div>
    <d-paging :pager="pager" @change="getItemList"></d-paging>

    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.show"
      width="40%"
      @close="dialogClose"
    >
      <el-dialog
        title="选择店铺"
        :visible.sync="innerVisible"
        append-to-body
        :close-on-click-modal="false"
        custom-class="mainShopDialog"
        @close="handleClose"
      >
        <p>选择楼层</p>
        <el-input
          v-model="filterText"
          placeholder="请输入店铺名称"
          size="medium"
          style="width: 260px; margin-top: 10px;"
          @change="filterShop"
        ></el-input>
        <el-tabs class="floor mainShopFloor" v-model="dialog.activeName">
          <el-tab-pane
            v-for="(item, index) in childList"
            :key="index"
            :label="item.name"
            :name="item.name"
          >
            <div class="floorShop">
              <el-checkbox-group
                v-model="dialog.checked"
                @change="handleChange"
              >
                <el-checkbox
                  :label="shop.shopName + 'id:' + shop.id"
                  v-for="(shop, index) in item.childList"
                  :key="index"
                >
                  {{ shop.name }}
                </el-checkbox>
                <!-- <el-checkbox v-for="item in 10" :key="item" @change="ladderChange" ></el-checkbox> -->
              </el-checkbox-group>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div slot="footer" class="dialog-footer">
          <el-button @click="innerDialogCancel">取 消</el-button>
          <el-button type="primary" @click="innerDialogConfirm">确定</el-button>
        </div>
      </el-dialog>
      <el-form
        ref="form"
        :rules="formRules"
        :model="dialog"
        label-width="100px"
      >
        <el-form-item label="所属店铺：" prop="shopName">
          <!-- :disabled="dialogTitle.includes('编辑')" -->
          <el-select
            v-model="dialog.shopName"
            placeholder="请选择店铺"
            @click.native="selectShopEvent"
            no-data-text=""
            style="width: 200px;"
            size="small"
          >
            <el-option
              v-for="item in shopOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <!-- <el-input
            v-model="dialog.shopName"
            :disabled="dialog.disabled"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="公司名称：" prop="name">
          <el-input
            v-model="dialog.name"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="desc">
          <el-input
            type="textarea"
            v-model="dialog.remark"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" v-throttle @click="confirmDialog">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from '@/components/d-search'
import DTable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import { options } from '@/components/options'
export default {
  data() {
    return {
      dialog: {
        id: '',
        show: false,
        title: null,
        disabled: false,
        name: '',
        shopName: '',
        remark: '',
        activeName: '',
        checked: [],
        action: '',
      },
      formRules: {
        shopName: this.$formValidator.empty('店铺名称', 'change'),
        name: this.$formValidator.empty('公司名称'),
      },
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      },
      searchData: {
        searchItem: [
          {
            label: '名称',
            value: 'name',
            type: 'commonInput',
            dateType: 'month',
            placeholder: '请输入名称',
          },
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
        },
        params: {},
      },
      columnData: [
        { prop: 'name', label: '快递公司名称' },
        { prop: 'shopName', label: '店铺名称' },
        { prop: 'remark', label: '备注', width: 120 },
        { prop: 'updateTime', label: '创建时间' },
        {
          prop: 'operate',
          label: '操作',
          width: 220,
          type: {},
        },
      ],
      tableData: [],
      /* 店铺列表 */
      shopOptions: [{}],
      childList: [],
      allShopList: [],
      /* 弹窗 */
      innerVisible: false,
      filterText: '', // 店铺搜索
      checkedShopIds: [],
      checkedShopName: '',
      orgChecked: [], // 打开店铺选择框之前选择的店铺
    }
  },
  components: {
    DSearch,
    DTable,
    DPaging,
  },
  created() {
    this.getItemList()
    this.getShopList()
  },
  // 方法集合
  methods: {
    // 过滤店铺
    filterShop(val) {
      if (val) {
        this.childList.forEach((item) => {
          item.childList = item.childList.filter((shop) =>
            shop.name.includes(val),
          )
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },

    // 显示选择店铺弹窗
    selectShopEvent() {
      // 编辑的时候所属店铺不能选择
      // if (this.dialogTitle.includes('编辑')) {
      //   return
      // }
      this.filterText = ''
      this.childList = JSON.parse(JSON.stringify(this.allShopList))
      this.orgChecked = this.dialog.checked
      this.innerVisible = true
    },
    // 里层弹窗确认
    innerDialogConfirm() {
      this.dialog.shopName = ''
      this.orgChecked = this.dialog.checked
      this.dialog.checked.forEach((item) => {
        const arr = item.split('id:')
        this.checkedShopIds.push(arr[1])
        this.dialog.shopName += `${arr[0]},`
      })
      this.innerVisible = false
    },
    innerDialogCancel() {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleClose() {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleResetDialog() {
      this.dialog = Object.assign(this.dialog, {
        id: undefined,
        show: false,
        title: null,
        disabled: false,
        name: '',
        shopName: '',
        remark: '',
        // activeName: '',
        checked: [],
        action: '',
      })  
    },
    // 选择店铺
    handleChange(v) {
      if (this.dialog.action == 'edit') {
        this.dialog.checked.length > 1 && this.dialog.checked.shift()
      }
    },
    /**
     * 搜索按钮点击
     **/
    searchEvent() {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent() {
      this.getItemList()
    },
    // 店铺列表
    getShopList() {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          let childList = JSON.parse(JSON.stringify(data.childList))
          let allChildList = []
          data.childList.forEach((child) => {
            child.childList.forEach((shop) => {
              allChildList.push(shop)
            })
          })
          console.log('allChildList', allChildList)
          let allShop = {
            name: '全部',
            childList: allChildList,
          }
          childList.unshift(allShop)
          this.allShopList = JSON.parse(JSON.stringify(childList))
          this.childList = JSON.parse(JSON.stringify(childList))
          this.dialog.activeName =
            this.allShopList.length > 0 ? this.allShopList[0].name : null
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList,
            },
          ]
          // this.shopLists = child
          this.searchData.searchItem.forEach((ele) => {
            if (ele.type === 'linkageSelect') {
              ele.options = child
            }
          })
        }
      })
    },

    // 获取列表
    async getItemList() {
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        shopId: '',
        name: this.searchData.params.name,
      }
      const {
        data,
        code,
        msg,
      } = await this.$api.shopManageApi.getLogisticsCompanyList(params)
      if (code === 200) {
        this.tableData = data.list
        this.pager.count = data.total
      }
    },

    // 点击添加按钮
    addItem() {
      const { dialog } = this
      dialog.title = '新增公司'
      dialog.action = 'add'
      dialog.show = true
    },
    changeItem(row) {
      const { dialog } = this
      dialog.id = row.id
      dialog.title = '编辑公司'
      dialog.action = 'edit'
      dialog.name = row.name
      dialog.shopName = row.shopName
      dialog.remark = row.remark
      dialog.checked = [row.shopName + 'id:' + row.shopId]
      dialog.show = true
    },
    // 删除
    async deleteItem(row) {
      const { code, msg } = await this.$api.shopManageApi.deleteLogisticsCompany({
        id: row.id,
      })
      if (code == 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(msg)
      }
    },
    // dialog确定
    confirmDialog() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { id, name, remark, action } = this.dialog
          const postData = {
            id: action == 'edit' ? id : undefined,
            name,
            remark,
            shopIds: this.checkedShopIds,
          }
          if (action == 'edit') {
            this.handleEditLogisticsCompany(postData)
          } else {
            this.handleAddLogisticsCompany(postData)
          }
        }
      })
    },
    // dialog取消
    cancelDialog() {
      this.handleResetDialog()
    },
    async handleAddLogisticsCompany(postData) {
      const data = await this.$api.shopManageApi.addLogisticsCompany(postData)
      if (data.code === 200) {
        this.$message.success('添加成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },
    async handleEditLogisticsCompany(postData) {
      const data = await this.$api.shopManageApi.updateLogisticsCompany(postData)
      if (data.code === 200) {
        this.$message.success('修改成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },
    // dialog关闭
    dialogClose() {
      this.handleResetDialog()
    },
  },
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.floor {
  margin-top: 10px;
  .floorShop {
    width: 100%;
    min-height: 300px;
    height: 300px;
    border: 1px solid #ccc;
    padding: 20px 10px;
    box-sizing: border-box;
    font-size: 12px;
    overflow: auto;
    .el-checkbox {
      margin: 20px 0 0 15px;
      margin-top: 20px;
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-checkbox:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
    // .operation {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   display: flex;
    //   div {
    //     line-height: 28px;
    //   }
    //   .el-button {
    //     margin-left: 20px;
    //   }
    //   .el-checkbox {
    //     height: 32px;
    //   }
    // }
  }

  .el-table {
    flex: 1;
    margin-top: 20px;
    overflow-y: auto;
  }
}

.d-paging {
  text-align: center;
}

::v-deep .el-form {
  width: 80%;
  margin: 0 auto;

  .el-select {
    width: 100%;
  }

  .el-range-editor.el-input__inner {
    width: 100%;
  }

  .hour {
    position: relative;
    display: flex;

    .vertical {
      display: inline-block;
      margin: 0 2%;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input {
      width: 90%;

      & > :last-child {
        margin-left: 10%;
      }
    }

    .addBtn {
      position: absolute;
      right: -40px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px dashed #ccc;
      font-size: 28px;
      color: #ccc;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-bottom: 4px;
      padding-left: 0.5px;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}
</style>
